const DATE_UNITS = {
  year: 31536000,
  month: 2626560,
  week: 604800,
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1
}

const getSecondsDiff = timestamp => (Date.now() - timestamp) / 1000

const getUnitAndValueDate = secondsElapsed => {
  for (const [unit, secondsInUnit] of Object.entries(DATE_UNITS)) {
    if (secondsElapsed >= secondsInUnit || unit === 'second') {
      const value = Math.floor(secondsElapsed / secondsInUnit) * -1
      return { value, unit }
    }
  }
}

export const getLocalDate = timestamp => {
  const local = new Date(parseInt(timestamp))
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }

  return local.toLocaleString(options)
}
export const getTimeAgo = timestamp => {
  const rtf = new Intl.RelativeTimeFormat('es', { numeric: 'auto' })
  const secondsElapsed = getSecondsDiff(timestamp)
  const { value, unit } = getUnitAndValueDate(secondsElapsed)

  return rtf.format(value, unit)
}
