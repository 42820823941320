<template>
  <b-card>
    <b-form>
      <b-row v-for="(elem, index) in content.element" :key="index">
        <b-col cols="12" md="12" class="mt-1" v-if="elem.type.toUpperCase() === 'INPUT'">
          <b-form-group :label-for="elem.field">
            <h5>
              <b-row>
                <b-col cols="8" md="9">
                  {{ elem.field }}
                </b-col>
                <b-col cols="1" md="1">
                  <b-button
                    :hidden="$session.user.role === 'EDITOR'"
                    class="btn-icon rounded-circle"
                    variant="flat-danger"
                    @click="deleteField(elem.field)"
                  >
                    <feather-icon icon="DeleteIcon" />
                  </b-button>
                </b-col>
                <b-col cols="1" md="1">
                  <b-button class="btn-icon rounded-circle" variant="flat-primary" @click="translate(elem)">
                    <feather-icon icon="GlobeIcon" />
                  </b-button>
                </b-col>
                <b-col cols="1" md="1">
                  <b-button class="btn-icon rounded-circle" variant="flat-primary" @click="copy(elem)">
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </h5>

            <validation-provider #default="{ errors }" :name="elem.field" rules="required">
              <b-form-input :key="index" v-model="elem.value" :placeholder="elem.placeholder || ''" :name="elem.field" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12" class="mt-1" v-if="elem.type.toUpperCase() === 'EDITOR'">
          <b-form-group :label-for="elem.field">
            <h5>
              <b-row>
                <b-col cols="8" md="9">
                  {{ elem.field }}
                </b-col>
                <b-col cols="1" md="1">
                  <b-button
                    :hidden="$session.user.role === 'EDITOR'"
                    class="btn-icon rounded-circle"
                    variant="flat-danger"
                    @click="deleteField(elem.field)"
                  >
                    <feather-icon icon="DeleteIcon" />
                  </b-button>
                </b-col>
                <b-col cols="1" md="1">
                  <b-button class="btn-icon rounded-circle" variant="flat-primary" @click="translate(elem)">
                    <feather-icon icon="GlobeIcon" />
                  </b-button>
                </b-col>
                <b-col cols="1" md="1">
                  <b-button class="btn-icon rounded-circle" variant="flat-primary" @click="copy(elem)">
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </h5>
            <validation-provider #default="{ errors }" :name="elem.field" rules="required">
              <quill-editor v-model="elem.value">
                <div id="toolbar" slot="toolbar"></div>
              </quill-editor>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12" class="mt-1" v-if="elem.type.toUpperCase() === 'GROUP'">
          <b-form-group>
            <h5>
              <b-row>
                <b-col cols="9" md="10"> </b-col>
                <!-- <b-col cols="1" md="1">
                  <b-button class="btn-icon rounded-circle" variant="flat-danger" @click="deleteField(elem.field)">
                    <feather-icon icon="DeleteIcon" />
                  </b-button>
                </b-col> -->
                <!-- Esta version no traduce grupos o listas completas, se debe hacer campo x campo
                <b-col cols="1" md="1">
                  <b-button class="btn-icon rounded-circle" variant="flat-primary" @click="translate(elem)">
                    <feather-icon icon="GlobeIcon" />
                  </b-button>
                </b-col>
                -->
              </b-row>
            </h5>
            <app-collapse accordion type="margin" class="group-element">
              <app-collapse-item :title="`${elem.field} (${content.block})`" class="group-element">
                <group
                  ref="group"
                  :block="content.block"
                  :pageName="pageName"
                  v-model="elem.groupValue"
                  :parentField="elem.field"
                  @deleteField="deleteField"
                ></group>
              </app-collapse-item>
            </app-collapse>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <small class="text-muted mr-50">{{ editData }}</small>
  </b-card>
</template>

<script>
import { BCard, BForm, BRow, BCol, BFormInput, BFormGroup, BButton } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { required } from '@validations'
import { getLocalDate, getTimeAgo } from '@/util/localDate'

localize('es')

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,

    ValidationProvider,
    ValidationObserver,

    AppCollapse,
    AppCollapseItem,

    group: () => import('@/views/components/pageAPI/group.vue' /* webpackChunkName: "group" */)
  },
  props: {
    content: {
      type: Object,
      default: () => ({})
    },
    updatedAt: {
      type: String,
      default: ''
    },
    pageName: {
      type: String,
      default: 'home'
    }
  },
  data: () => ({
    // validations
    required
  }),
  computed: {
    editData() {
      return this.updatedAt ? `Publicado el: ${this.toLocalDate(this.updatedAt)} (${this.toTimeAgo(this.updatedAt)})` : ''
    },
    page() {
      return this.$store.getters['storyStore/getPage'](this.pageName)
    }
  },
  methods: {
    toLocalDate(t) {
      return getLocalDate(t)
    },
    toTimeAgo(t) {
      return getTimeAgo(t)
    },
    deleteField(fi) {
      const self = this
      this.$swal({
        title: 'Eliminar?',
        text: `Eliminaremos el campo: ${fi}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) self.$emit('deleteField', fi)
      })
    },
    copy(field) {
      const self = this
      this.$swal({
        title: `Copiar ${field.field}?`,
        text: 'El campo se copiará a todos los lenguajes sin traducción',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, copiar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return self.$store
            .dispatch('storyStore/copy', { selectedField: field, page: self.page })
            .then(() => self.$noti.success(`Copia lista!!`))
            .catch(() =>
              self.$swal({
                icon: 'error',
                title: 'Error de Copia',
                text: 'Ha ocurrido un error en la copia del texto.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
            )
        }
      })
    },
    translate(field) {
      const self = this
      this.$swal({
        title: `Iniciar la traducción del campo ${field.field}?`,
        text: 'Traduciremos automáticamente el campo seleccionado',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, traducir',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return self.$store
            .dispatch('storyStore/translate', { selectedField: field, page: self.page })
            .then(() =>
              self.$swal({
                icon: 'success',
                title: 'Traducción Lista',
                text: 'Ha finalizado la traducción del campo, te recomendamos revisar cuidadosamente los resultados.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
            )
            .catch(() =>
              self.$swal({
                icon: 'error',
                title: 'Error de Traducción',
                text: 'Ha ocurrido un error en el servicio de traducción.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
            )
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/variables';

.group-element {
  background: $info;
  // background: rgba(92, 100, 219, 0.164);
}
</style>

<style scoped>
.list-item {
  display: inline-block;
  /* margin-right: 10px; */
}
.list-enter-active,
.list-leave-active {
  transition: all 3s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
